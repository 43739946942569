<template>
  <div class="caseData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="案例时间：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <!-- <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.fieldIdName"
          query-name="fieldIdName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-tag-button
          ref="tagButton"
          :code.sync="queryInfo.techIdName"
          query-name="techIdName"
          name="技术类别"
          tag="技术"
          key="技术类别"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-tag-button
          :code.sync="queryInfo.industryName"
          query-name="industryName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item> -->
      <el-form-item label="案例类型：">
        <dic-radio-button
          :code.sync="queryInfo.softwareHardware"
          type-code="024"
          query-name="softwareHardware"
          name="案例类型"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.planCountry"
          :province="queryInfo.planProvince"
          :city="queryInfo.planCity"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <!-- 新增筛选项： 赋能行业 -->
      <el-form-item label="赋能行业：">
        <dic-tag-button
          :code.sync="queryInfo.industryName"
          query-name="industryName"
          name="赋能行业"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加</el-button
          >

          <import-data @fileClick="fileClick" moduleName="案例数据" />

          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor'
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor'
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="根据关键词进行全文搜索"
          v-model="queryInfo.planKeyword"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="caseDataTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @cell-dblclick="tableDoubleClick"
        :cell-style="set_cell_style"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column type="selection" align="center" />
        <el-table-column prop="id" label="序号" align="center" width="80">
          <template v-slot="scope">
            <span>{{
              (queryInfo.pageNum - 1) * queryInfo.pageSize + (scope.$index + 1)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="数据完整度"
          align="center"
          prop="dataIntegrity"
          width="120"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <el-progress
                type="circle"
                :percentage="row.dataIntegrity ? row.dataIntegrity : 0"
                :stroke-width="12"
              ></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="planTitle" label="标题" align="center">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.planTitle }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 新增筛选项： 赋能行业 -->
        <el-table-column
          prop="industryCategory1"
          label="赋能行业"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.industryCategory1 !== null &&
                  scope.row.industryCategory1 !== '' &&
                  scope.row.industryCategory1
              "
            >
              <el-popover
                placement="bottom-start"
                width="200"
                trigger="hover"
                :content="scope.row.industryCategory1"
              >
                <span slot="reference"
                  >{{ scope.row.industryCategory1.substring(0, 20) }}
                  <span v-if="scope.row.industryCategory1.length > 20"
                    >...</span
                  ></span
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="领域类别" align="center" width="180"> -->
        <!-- <template v-slot="{row}">
            <span class="tableColor">{{row.wisdomLabel}}</span>
          </template> -->
        <!-- <template slot-scope="scope">
            <div
              v-if="
                scope.row.wisdomLabel !== null &&
                scope.row.wisdomLabel !== '' &&
                scope.row.wisdomLabel
              "
              class="tableColor"
            >
              {{ scope.row.wisdomLabel.substring(0, 20) }}
              <span v-if="scope.row.wisdomLabel.length > 20">...</span>
            </div>
          </template>
        </el-table-column> -->
        <!-- <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column label="技术类别" align="center" width="180"> -->
        <!-- <template v-slot="{row}">
            <span class="tableColor">{{row.aiLabel}}</span>
          </template> -->
        <!-- <template slot-scope="scope">
            <div
              v-if="
                scope.row.aiLabel !== null &&
                scope.row.aiLabel !== '' &&
                scope.row.aiLabel
              "
              class="tableColor"
            >
              {{ scope.row.aiLabel.substring(0, 20) }}
              <span v-if="scope.row.aiLabel.length > 20">...</span>
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="行业类别" align="center" width="180"> -->
        <!-- <template v-slot="{row}">
            <span class="tableColor">{{row.tradeLabel}}</span>
          </template> -->
        <!-- <template slot-scope="scope">
            <div
              v-if="
                scope.row.tradeLabel !== null &&
                scope.row.tradeLabel !== '' &&
                scope.row.tradeLabel
              "
              class="tableColor"
            >
              {{ scope.row.tradeLabel.substring(0, 20) }}
              <span v-if="scope.row.tradeLabel.length > 20">...</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="softwareHardware"
          label="案例类型"
          align="center"
          width="80"
        />
        <!-- <el-table-column prop="planTime" label="案例时间" align="center" sortable="custom" width="120"/> -->
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{ row }">
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="dialogType"
        :visible.sync="dialogVisible"
        width="60%"
        @close="handleClose"
        center
      >
        <el-form :model="editTableObj" label-width="100px">
          <el-form-item label="行业类别：" v-if="dialogType === '行业类别'">
            <dic-checkbox-group
              ref="checkboxCase"
              :code.sync="editTableObj.sectorss"
              tag="行业"
              key="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item
            label="领域类别："
            v-else-if="dialogType === '领域类别'"
          >
            <dic-checkbox-group
              ref="checkboxCase"
              :code.sync="editTableObj.wisdomLabels"
              tag="领域"
              key="领域"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="技术类别：" v-else>
            <dic-checkbox-group
              ref="checkboxCase"
              :code.sync="editTableObj.labels"
              tag="技术"
              key="技术"
              @updateCode="updateCode"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="saveItem">保 存</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getCaseData, deleteCaseData } from "@/api/caseData";
import DataTime from "./dataTime";
import DicTagButton from "@/components/DicTagButton.vue";
import CascaderArea from "@/components/CascaderArea.vue";
import { pagination } from "@/mixins/pagination";
import ImportData from "@/components/ImportData.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { updateCaseData } from "@/api/caseData";
export default {
  name: "CaseData",

  mixins: [pagination],

  components: {
    DicTagButton,
    CascaderArea,
    ImportData,
    ButtonTable,
    DataTime,
    DicRadioButton,
    DicCheckboxGroup
  },

  data() {
    return {
      valueI: 0,
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        year: null, // 年
        month: null, // 月
        columnName: "modification,create_time",
        softwareHardware: "", // 案例类型
        wisdomLabels: [], // 领域类别
        fieldIdName: "", // 领域类别
        planCountry: "", // 案例国家
        planProvince: "", // 案例省份
        planCity: "", // 案例地区
        labels: [], // 技术类别
        techIdName: "",
        sectorss: [], // 行业类别
        industryName: "",
        planKeyword: "", // 关键字
        order: 1 // 排序
      },
      caseDataTable: [],
      delete: {
        id: [],
        multiIds: []
      },
      tags: [
        // {
        //   name: "领域类别",
        //   tagName: "fieldIdName",
        //   valLength: 0,
        // },
        // {
        //   name: "技术类别",
        //   tagName: "techIdName",
        //   valLength: 0,
        // },
        // {
        //   name: "行业类别",
        //   tagName: "industryName",
        //   valLength: 0,
        // },
        {
          name: "案例类型",
          tagName: "softwareHardware"
        },
        {
          name: "赋能行业",
          tagName: "industryName",
          valLength: 0
        }
      ],
      dialogType: "行业类别",
      dialogVisible: false,
      editTableObj: {
        tradeLabels: [], // 行业
        industryChildrenId: [], // 行业类别二级标签
        wisdomLabels: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
        aiLabels: [], // 技术
        techChildrenId: [] // 技术类别二级标签
      }
    };
  },

  created() {
    this.search();
  },

  methods: {
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },
    async search(num) {
      const res = await getCaseData(this.queryInfo);

      if (res.code === 200) {
        this.caseDataTable = res.data.list;
        this.total = res.data.total;
        this.caseDataTable.forEach(el => {
          if (el.dataInte) {
            if (el.dataInte.length) {
              el.dataInte = Number(el.dataInte.slice(0, -1));
            } else {
              el.dataInte = 0;
            }
          }
        });

        this.total = res.data.total;
      }
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;

      this.$refs.checkboxCase.clear();
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "industryName":
          this.queryInfo.industryName = "";
          this.queryInfo.sectorss = [];
          break;
        case "fieldIdName":
          this.queryInfo.fieldIdName = "";
          this.queryInfo.wisdomLabels = [];
          break;
        case "techIdName":
          this.queryInfo.techIdName = "";
          this.queryInfo.labels = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.search();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.search();
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      // 解决筛选条件后点击第二条，换筛选条件后还在第二条问题
      this.queryInfo.pageNum = 1;
      let childrenVal = [];

      if (!this.tags.some(item => item.tagName === queryName)) {
        if (
          name === "行业类别" ||
          name === "领域类别" ||
          name === "技术类别" ||
          name === "赋能行业"
        ) {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex(el => {
        return el.name === name;
      });

      switch (name) {
        case "行业类别":
          this.queryInfo.sectorss = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.sectorss = this.queryInfo.sectorss.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.wisdomLabels = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.wisdomLabels = this.queryInfo.wisdomLabels.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "技术类别":
          this.queryInfo.labels = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.labels = this.queryInfo.labels.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "赋能行业":
          this.queryInfo.industryCategoryList = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.industryCategoryList = this.queryInfo.industryCategoryList.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }

      this.search();
    },
    searchCountry(val) {
      this.queryInfo.planCountry = val;

      this.search();
    },
    searchProvince(val) {
      this.queryInfo.planProvince = val;

      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.planCity = val;
        this.search();
      }
    },
    //添加
    addItem() {
      let queryData = JSON.stringify({});
      this.$router.push({
        // name: "CaseDataInfo",
        name: "CaseDataParent",
        query: {
          projectDataType: 0,
          editInfo: queryData
        }
      });
    },
    // 数据上传
    fileClick() {
      this.search();
    },
    handleSelectionChange(val) {
      this.delete.multiIds = val.map(item => {
        return item.id;
      });
    },
    _deleteCase(params) {
      this.$confirm("确认删除吗 ?", "提示")
        .then(async () => {
          const res = await deleteCaseData(params);

          if (res.code === 200) {
            this.$message.success("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    },
    // 批量删除
    multiDelete() {
      if (this.delete.multiIds.length > 1) {
        this._deleteCase(this.delete.multiIds);
      } else {
        this.$message.error("批量删除必须大于一条");
      }
    },
    // 删除
    deleteItem(row) {
      this.delete.id.push(row.id);

      this._deleteCase(this.delete.id);
    },
    uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "create_time,modification";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    caretTop() {
      // this.queryInfo.order = 0;
      // this.search();
    },
    caretBottom() {
      // this.queryInfo.order = 1;
      // this.search();
    },
    // 双击单元格
    tableDoubleClick(row, column) {
      if (
        column.label === "领域类别" ||
        column.label === "行业类别" ||
        column.label === "技术类别"
      ) {
        Object.assign(this.editTableObj, row);

        this.editTableObj.wisdomLabels = this.editTableObj.wisdomLabel; // 领域
        this.editTableObj.labels = this.editTableObj.aiLabel; // 技术
        this.editTableObj.sectorss = this.editTableObj.tradeLabel; // 行业

        // 技术
        this.editTableObj.labels = this.formatString(this.editTableObj.labels);

        // 行业
        this.editTableObj.sectorss = this.formatString(
          this.editTableObj.sectorss
        );

        // 领域
        this.editTableObj.wisdomLabels = this.formatString(
          this.editTableObj.wisdomLabels
        );

        if (column.label === "领域类别") {
          this.dialogType = "领域类别";
        } else if (column.label === "行业类别") {
          this.dialogType = "行业类别";
        } else if (column.label === "技术类别") {
          this.dialogType = "技术类别";
        }

        this.dialogVisible = true;

        if (this.$refs.checkboxCase) {
          this.$refs.checkboxCase.getDicData();
        }
      }
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.wisdomLabels = this.editTableObj.wisdomLabels.concat(
          this.editTableObj.fieldChildrenId
        );
      }

      if (this.editTableObj.techChildrenId) {
        this.editTableObj.labels = this.editTableObj.labels.concat(
          this.editTableObj.techChildrenId
        );
      }

      if (this.editTableObj.industryChildrenId) {
        this.editTableObj.sectorss = this.editTableObj.sectorss.concat(
          this.editTableObj.industryChildrenId
        );
      }

      this.editTableObj.sectorss = [...new Set(this.editTableObj.sectorss)];
      this.editTableObj.labels = [...new Set(this.editTableObj.labels)];
      this.editTableObj.wisdomLabels = [
        ...new Set(this.editTableObj.wisdomLabels)
      ];

      const res = await updateCaseData(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible = false;

      this.search();
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.editTableObj.fieldChildrenId = val;
          break;

        case "技术":
          this.editTableObj.techChildrenId = val;
          break;

        case "行业":
          this.editTableObj.industryChildrenId = val;
          break;

        default:
          break;
      }
    },
    // 编辑
    editItem(row) {
      let queryData = JSON.stringify(row);
      this.$router.push({
        name: "CaseDataParent",
        query: {
          projectDataType: 1,
          editInfo: row.id
        }
      });
      // this.$router.push({
      //   name: "editTalentQY",
      //   params: {
      //     personData: data,
      //     personType: 1,
      //   },
      // });
      // let text = this.$router.resolve({
      //   path: "/caseData/caseDataInfo",
      //   query: {
      //     projectDataType: 1,
      //     editInfo: row.id,
      //   },
      // });
      // window.open(text.href, "_blank");
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (
        column.label === "领域类别" ||
        column.label === "技术类别" ||
        column.label === "行业类别"
      ) {
        return "cursor:pointer";
      }
    },
    sortChange(column) {
      this.queryInfo.columnName = "data_integrity";
      this.pageNum = 1;
      // dataInte
      if (column.prop === "dataIntegrity") {
        this.queryInfo.columnName = "data_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 1;
        } else if (column.order === "descending") {
          this.queryInfo.order = 0;
        } else {
          this.queryInfo.order = 0;
          this.queryInfo.columnName = "create_time,modification";
        }
      } else if (column.prop === "planTime") {
        this.queryInfo.columnName = "plan_time";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "create_time,modification";
        }
      }
      this.search();
    }
  }
};
</script>

<style lang="less" scoped>
.caseData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
</style>
